import React, { useState } from 'react';
import BackgroundImage from 'gatsby-background-image';
import iconPlay from '../../images/icon-play.svg';

export default function MediaContent({ thumbnail }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoUrl = 'https://www.youtube.com/embed/bjJOurqcq2E';
  return (
    <div className="method-content">
      <div className="wrapper wrapper-lg pd-x-md">
        <div className="media ratio_16-9">
          <iframe
            width="560"
            height="315"
            src={`${videoUrl}${isPlaying ? '?autoplay=1&rel=0' : ''}`}
            style={{ display: isPlaying ? 'inherit' : 'none' }}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          {!isPlaying && (
            <>
              <div className="m_overlay"></div>
              <BackgroundImage
                className="m_overlay-image bgImage"
                fluid={thumbnail.childImageSharp.fluid}
                style={{ position: 'absolute' }}
              />
              <div className="media-content">
                <div onClick={() => setIsPlaying(true)} className="btn btn-sm btn-primary-ii btn-play-icon">
                  <img src={iconPlay} alt="Icon Play" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
